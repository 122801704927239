<script>
    import axios from "axios";
    import AuthPageCard from "@components/auth/AuthPageCard";

    export default {
      components: {AuthPageCard},
      page: {
            title: 'Επαναφορά κωδικού',
            meta: [{ name: 'description', content: `Επαναφορά κωδικού στο athlisis` }],
        },
        data() {
            return {
                email: '',
                recoverHash: '',
                newPassword: '',
                repeatNewPassword: '',
                error: '',
                success: null,
            }
        },
        computed: {
        },
        mounted() {
            this.email = this.$route.query.email;
            this.recoverHash = this.$route.query.recover_hash;
        },
        methods: {
            // ...authMethods,
            changePassword(){
                this.error = '';

                if(this.newPassword !== this.repeatNewPassword){
                    this.error = 'Οι κωδικοί πρόσβασης δεν είναι ίδιοι!';
                    return;
                }

                axios.post(`${process.env.VUE_APP_MOBILE_API}/users/forgot-password/recover`,
                    { email: this.email, password: this.newPassword, recoverHash: this.recoverHash}).then(result => {
                    this.success = 'Ο Κωδικός πρόσβασής σας άλλαξε επιτυχώς';
                }).catch(e =>{
                    this.error = "Κάτι πήγε λάθος. Προσπαθήστε ξανά.";
                    console.log(e);
                })

            }
        },
    }
</script>

<template>
<div id="mobile-recover">

  <auth-page-card>
    <template v-slot:default>
      <div class="mt-3 text-center">
        <h3>Επαναφορά κωδικού</h3>
        <p class="text-muted mt-2"> Εισάγετε έναν νέο κωδικό πρόσβασης για την πιστοποίησή σας στην εφαρμογή κινητού του Athlisis.</p>
        <b-form v-if="!success" @submit.prevent="changePassword">

          <b-form-input id="email" v-model="email" type="text" disabled></b-form-input>

          <b-form-group id="password" label="Νέος κωδικός πρόσβασης" label-for="new-password" class="pt-3">
            <b-form-input id="new-password" v-model="newPassword" type="password" required placeholder="Εισάγετε έναν κωδικό πρόσβασης"></b-form-input>
          </b-form-group>
          <b-form-group id="new-password" label="Επανάληψη νέου κωδικού πρόσβασης" label-for="repeat-new-password">
            <b-form-input id="repeat-new-password" v-model="repeatNewPassword" type="password" required placeholder="Επαναλάβετε τον κωδικό πρόσβασης"></b-form-input>
          </b-form-group>
          <b-form-group id="button-group" class="mt-4">
            <b-button type="submit" variant="primary" class="btn-block mobile-btn">Αλλαγή κωδικού πρόσβασης</b-button>
          </b-form-group>

          <b-alert :show="error !== ''" variant="danger">{{error}}</b-alert>
        </b-form>
        <b-alert :show="success !== null" variant="success">{{success}}</b-alert>
      </div>
    </template>
  </auth-page-card>

</div>
</template>

<style lang="scss">
    #mobile-recover{
        input{
            border-radius:99px;
        }
        .mobile-btn{
            padding: 10px 10px;
            border-radius:99px;
        }
    }

</style>
